import moment from "moment";
import {removeDuplicates} from "@components/help";
import {TwoDecimalFormat} from "@components/Format";

export const FacilityBenchmarkProps = {
    title: 'FacilityBenchmark',
    description: '',
    name: 'FacilityBenchmark'
}

export const FacilityBenchmarkColumns = (data) => {

    let yearOptions = data.map((e) => ({'label': e.year, 'value': e.year}))
    yearOptions = removeDuplicates(yearOptions)
    yearOptions.sort((a, b) => a.value - b.value)

    let categoryOptions = data.map((e) => ({'label': e.category, 'value': e.category}))
    categoryOptions = removeDuplicates(categoryOptions)
    categoryOptions.sort((a, b) => a.value - b.value)

    let regionOptions = data.map((e) => ({'label': e.region, 'value': e.region}))
    regionOptions = removeDuplicates(regionOptions)
    regionOptions.sort((a, b) => a.value - b.value)

    let climatezonOptions = data.map((e) => ({'label': e.eui_climate_zoneng, 'value': e.eui_climate_zoneng}))
    climatezonOptions = removeDuplicates(climatezonOptions)
    climatezonOptions.sort((a, b) => a.value - b.value)

    return [
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
            edit: true,
            // sorter: (a, b) => a.Company.localeCompare(b.Company),
        },
        {
            title: 'Facility',
            dataIndex: 'facility',
            key: 'facility',
        },
        {
            title: 'Year Built',
            dataIndex: 'year',
            key: 'year',
            hideInTable: window.innerWidth < 760,
            render: (text, record) => (
                moment(record.year).format('YYYY')
            ),
            valueType: 'text',
        }, {
            title: 'Size (sqft)',
            dataIndex: 'size',
            key: 'size',
        }, {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            width: '20rem',

        }, {
            title: 'EUI (kBtu/q-yr)',
            dataIndex: 'eui',
            key: 'eui',

        }, {
            title: 'EUI - Elec(kBtu/sq-yr)',
            dataIndex: 'eui_elec',
            key: 'eui_elec',

        }, {
            title: 'EUI - NG (kBtu/sq-yr)',
            dataIndex: 'eui_ng',
            key: 'eui_ng',

        }, {
            title: 'Energy Costs ($)',
            dataIndex: 'energy_costs',
            key: 'energy_costs',

        }, {
            title: 'Energy Costs ($/sqft)',
            dataIndex: 'energy_costs_sqft',
            key: 'energy_costs_sqft',
            render: (text) => (
                TwoDecimalFormat(text)
            ),
        }, {
            title: 'Region',
            dataIndex: 'region',
            key: 'region',

        }, {
            title: 'Climate Zone',
            dataIndex: 'eui_climate_zoneng',
            key: 'eui_climate_zoneng',

        },
    ]
};

import {Button, DatePicker, Form} from "antd";
import React, {useState} from "react";

export const ElectricityFilters = (props) => {

    return <DatePicker.RangePicker
        allowClear
        placeholder={['Start Date', 'End Date']}
        // value={startModifiedDate}
        getPopupContainer={trigger => trigger.parentNode}
        onChange={e => {
            props.handleFilter({
                startModifiedDate: e?.[0] ?? null,
                endModifiedDate: e?.[1] ?? null
            })
        }}
    />

}

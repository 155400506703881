import React from "react";
import { Tag } from "antd";

export const CertifyStatus = {
    Certified: 'Certified',
    PendingCertification: 'Pending Certification',
    Uncertified: 'Uncertified',
    Rejected: 'Rejected',
}

const CertifyStatusTag = ({ status }) => {
    if(!status) {
        return null
    }
    return <Tag color={status === CertifyStatus.Certified ? 'green' : status === CertifyStatus.PendingCertification ? 'orange' : status === CertifyStatus.Uncertified ? 'gray' : 'red'}>{status}</Tag>
}

export default CertifyStatusTag;

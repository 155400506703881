import React, { useState } from "react"
import { api } from '@/redux/api';
import { useRequest } from "ahooks";
import { useParams } from "react-router-dom";
import { message, Form, Row, Col, Input, Steps, Collapse, Button, Space, Card, Spin, Typography, Radio } from "antd";
import CustomDatePicker from "@components/CustomDatePicker";
import moment from "moment";
import styled from "styled-components";
import { useKeepAliveContext } from "@/hooks/use-keep-alive-context";
import { EventName, useEventBus } from "@/hooks/useEventBus";

const { Title } = Typography;
const Label = styled.span`
    font-weight: 600;
    color: #6A74A5 !important;
    font-size: 11px;
    margin-right: 10px;
    padding: 12px 0;
    display: inline-block;
`

export const GasDetail = () => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const [isEditing, setIsEditing] = useState(false);
    const { closeTab, renameTab } = useKeepAliveContext();

    const { data = {}, loading: fetchLoading, error, runAsync: refetch } = useRequest(
        () => api.getGasById(id),
        {
            onSuccess: (data) => {
                form.setFieldsValue(data);
                renameTab(`/GasDetail/${data.id}`, `Gas Details(${data.id})`);
            },
            onError: (err) => {
                console.error('Failed to fetch gas details:', err);
                message.error('Failed to fetch gas details');
            }
        }
    );

    useEventBus(EventName.GAS_UPDATE, (data) => {
        if (data.id === +id) {
            refetch();
        }
    });

    useEventBus(EventName.GAS_DELETE, (deleteId) => {
        if (deleteId === +id) {
            closeTab(`/GasDetail/${id}`);
        }
    });

    const save = () => {
        form.validateFields().then(values => {
            api.editGas(id, values).then(res => {
                if (res.status === 200) {
                    message.success('Save successfully');
                    refetch();
                    setIsEditing(false);
                }
            }).catch(errorInfo => {
                console.log('saveGas', errorInfo)
            });
        }).catch(errorInfo => {
            console.log('saveGas', errorInfo)
        });
    }

    const handleCancel = () => {
        setIsEditing(false);
        if (data) {
            form.setFieldsValue(data);
        }
    }

    return (
        <div style={{ padding: '20px' }}>
            <Spin spinning={fetchLoading} tip="Loading...">
                <Card
                    title={<Title level={4}>{data?.id ? `Gas Details(${data.id})` : 'Gas Details'}</Title>}
                    extra={
                        isEditing ? (
                            <>
                                <Space size={16}>
                                    <Button onClick={handleCancel} style={{ marginRight: 26 }}>
                                        Cancel
                                    </Button>
                                    <Button
                                        type="primary"
                                        onClick={save}
                                        style={{ marginRight: 8 }}
                                    >
                                        Save
                                    </Button>
                                </Space>
                            </>
                        ) : (
                            <Space size={36}>
                                <Button type="primary" onClick={() => setIsEditing(true)}>
                                    Edit
                                </Button>
                            </Space>
                        )
                    }
                >
                    <Form
                        form={form}
                        layout="vertical"
                        disabled={!isEditing}
                    >
                        <Row gutter={24}>
                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="Service Start Date"
                                    name="service_start_date"
                                    rules={[{ required: true, message: 'Please select service start date!' }]}
                                >
                                    <CustomDatePicker format='YYYY-MM-DD' />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="Service End Date"
                                    name="end_start_date"
                                    rules={[{ required: true, message: 'Please select service end date!' }]}
                                >
                                    <CustomDatePicker format='YYYY-MM-DD' />
                                </Form.Item>
                            </Col>

                            <Col xs={24}>
                                <Form.Item
                                    label="Description"
                                    name="description"
                                >
                                    <Input.TextArea rows={3} />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="Service Month Manual Override"
                                    name="manual_update"
                                >
                                    <Radio.Group defaultValue={false}>
                                        <Radio value={true}>True</Radio>
                                        <Radio value={false}>False</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="Service Month"
                                    name="service_month"
                                >
                                    <CustomDatePicker format='YYYY-MM-DD' />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="Usage(MMBTU)"
                                    name="usage"
                                    rules={[{ required: true, message: 'Please input usage!' }]}
                                >
                                    <Input type="number" />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="Avg. OAT(°F)"
                                    name="avgoat"
                                    rules={[{ required: true, message: 'Please input average OAT!' }]}
                                >
                                    <Input type="number" />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="Costs($)"
                                    name="costs"
                                    rules={[{ required: true, message: 'Please input costs!' }]}
                                >
                                    <Input type="number" />
                                </Form.Item>
                            </Col>

                            <Col xs={24} md={12}>
                                <Form.Item
                                    label="Adjustment"
                                    name="adjustment"
                                >
                                    <Input type="number" />
                                </Form.Item>
                            </Col>

                            <Col xs={24}>
                                <Collapse defaultActiveKey={['1']}>
                                    <Collapse.Panel header="Certification History" key="1">
                                        <Steps
                                            progressDot
                                            className="px-8"
                                            direction="vertical"
                                            items={[
                                                {
                                                    title: 'Submitted By Admin',
                                                    status: 'finish',
                                                    description: '2024-01-01',
                                                },
                                                {
                                                    title: 'Approved By Admin',
                                                    status: 'process',
                                                    description: '2024-01-01',
                                                },
                                                {
                                                    title: 'Rejected By Admin',
                                                    status: 'process',
                                                    description: '2024-01-01',
                                                },
                                                {
                                                    title: 'Rejected By Admin',
                                                    status: 'process',
                                                    description: '2024-01-01',
                                                },
                                                {
                                                    title: 'Submitted By User',
                                                    status: 'error',
                                                    description: '2024-01-01',
                                                },
                                            ]}
                                        />
                                    </Collapse.Panel>
                                </Collapse>
                            </Col>

                            <Col xs={24} md={12}>
                                <Label>Created By:</Label>{data?.createdby_name}
                            </Col>

                            <Col xs={24} md={12}>
                                <Label>Created Date:</Label>{data.created_date ? moment(data.created_date).format('YYYY-MM-DD HH:mm:ss') : ''}
                            </Col>

                            <Col xs={24} md={12}>
                                <Label>Modified By:</Label>{data?.lastModified_name}
                            </Col>

                            <Col xs={24} md={12}>
                                <Label>Modified Date:</Label>{data.modified_date ? moment(data.modified_date).format('YYYY-MM-DD HH:mm:ss') : ''}
                            </Col>

                            <Form.Item name="id" hidden />
                        </Row>
                    </Form>
                </Card>
            </Spin>
        </div>
    );
};
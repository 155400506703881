import { useEffect } from 'react';
import mitt from 'mitt';

const eventBus = mitt();

export const EventName = {
    // 刷新用户详情
    USER_DETAIL_REFRESH: 'USER_DETAIL_REFRESH',

    GAS_UPDATE: 'GAS_UPDATE',
    GAS_DELETE: 'GAS_DELETE',
    ELECTRICITY_UPDATE: 'ELECTRICITY_UPDATE',
    ELECTRICITY_DELETE: 'ELECTRICITY_DELETE',
}

export const useEventBus = (eventName, callback) => {
    useEffect(() => {
        eventBus.on(eventName, callback);
        return () => {
            eventBus.off(eventName, callback);
        };
    }, [eventName, callback]);
};

export const emitEvent = (eventName, data) => {
    eventBus.emit(eventName, data);
};

import {Button, DatePicker, Form, Select} from "antd";
import React, {useState} from "react";
import moment from "moment/moment";

export const FacilityBenchmarkFilters = (props) => {

    const [start_year, setStartYear] = useState([]);
    const [end_year, setEndYear] = useState([]);
    const [category, setCategory] = useState([]);
    const [region, setRegion] = useState([]);
    const [climate_zone, setClimate_zone] = useState([]);
    const [company, setCompany] = useState('CurrentCompany');

    const formRef = React.useRef()

    function handleStartYear(value) {
        setStartYear(value)
    }

    function handleEndYear(value) {
        setEndYear(value)
    }

    function handleCategory(category) {
        if (category?.includes('All')) {
            formRef.current.setFieldsValue({
                category: ['All']
            })
        }
        setCategory(category)
    }

    function handleRegion(region) {
        if (region?.includes('All')) {
            formRef.current.setFieldsValue({
                region: ['All']
            })
        }
        setRegion(region)
    }

    function handleClimate_zone(climate_zone) {
        if (climate_zone?.includes('All')) {
            formRef.current.setFieldsValue({
                climate_zone: ['All']
            })
        }
        setClimate_zone(climate_zone)

    }

    function handleCompany(company) {
        if (company?.includes('All')) {
            formRef.current.setFieldsValue({
                company: ['']
            })
        }
        setCompany(company)

    }

    function disabledBaseMinMaxDate(current) {

        let curr = moment(current).year();

        return !(curr >= props.start_min_year && curr <= props.end_max_year)
    }

    const startYearFilter = () => {
        return (
            <DatePicker placeholder={'Start Year'}
                        value={start_year}
                        disabledDate={disabledBaseMinMaxDate}
                        onChange={handleStartYear} picker="year"/>
        )
    }

    const endYearFilter = () => {
        return (
            <DatePicker placeholder={'End Year'}
                        value={end_year}
                        disabledDate={disabledBaseMinMaxDate}
                        onChange={handleEndYear} picker="year"/>
        )
    }

    const categoryFilter = () => {
        return (
            <Select
                mode="multiple"
                allowClear
                value={category}
                style={{
                    width: '100%',
                }}
                getPopupContainer={trigger => trigger.parentNode}
                placeholder="Category"
                onChange={handleCategory}
                options={props.category_options}
            />
        )
    }
    const regionFilter = () => {
        return <Select
            mode="multiple"
            allowClear
            value={region}
            style={{
                width: '100%',
            }}
            getPopupContainer={trigger => trigger.parentNode}
            placeholder="Region"
            onChange={handleRegion}
            options={props.region_options}
        />
    }

    const climateZoneFilter = () => {
        return <Select
            mode="multiple"
            allowClear
            value={climate_zone}
            style={{
                width: '100%',
            }}
            getPopupContainer={trigger => trigger.parentNode}
            placeholder="Climate Zone"
            onChange={handleClimate_zone}
            options={props.climate_zone_options}
        />
    }

    const companyFilter = () => {
        return <Select
            mode="single"
            allowClear
            value={company}
            style={{
                width: '100%',
            }}
            getPopupContainer={trigger => trigger.parentNode}
            placeholder="Company"
            onChange={handleCompany}
            options={props.company_options}
        />
    }

    function handleSubmit(e) {
        props.handleFilter(e)
    }

    const handleValuesChange = (_, e) => {
        props.handleFilter(e)
    }

    return <Form
        id="myForm"
        layout="horizontal"
        onFinish={handleSubmit}
        ref={formRef}
        onValuesChange={handleValuesChange}
    >
        {/* <Form.Item name={'company'} className={'md:w-60 lg:w-60 sm:w-full'}>
            {companyFilter()}
        </Form.Item> */}

        <Form.Item name={'start_year'}>
            {startYearFilter()}
        </Form.Item>

        <Form.Item name={'end_year'}>
            {endYearFilter()}
        </Form.Item>

        <Form.Item name={'region'} className={'md:w-60 lg:w-60 sm:w-full'}>
            {regionFilter()}
        </Form.Item>

        <Form.Item name={'category'} className={'md:w-60 lg:w-60 sm:w-full'}>
            {categoryFilter()}
        </Form.Item>

        <Form.Item name={'climate_zone'} className={'md:w-60 lg:w-60 sm:w-full'}>
            {climateZoneFilter()}
        </Form.Item>

        {/* <Form.Item>
            <Button type="primary" htmlType="submit">Submit</Button>
        </Form.Item> */}
    </Form>

}

import React from 'react';
import { ArrowDownOutlined, ArrowUpOutlined, CaretDownOutlined, CaretUpOutlined, InfoCircleOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

const DashboardCard = ({
    title,
    value,
    unit,
    percent,
    startDate,
    endDate,
    isIncreased,
    variant
}) => {
    const getVariantColor = () => {
        switch (variant) {
            case 'blue':
                return '#5470c6';
            case 'green':
                return '#0000ff';
            case 'purple':
                return '#f67e38';
            case 'orange':
                return '#c00000';
            default:
                return '#3B82F6';
        }
    };

    const getWaveDelay = (variant) => {
        switch (variant) {
            case 'blue':
                return '-5s';
            case 'green':
                return '-10s';
            case 'purple':
                return '-15s';
            case 'orange':
                return '-20s';
            default:
                return '-10s';
        }
    };

    const cardStyles = {
        width: '100%',
        backgroundColor: 'white',
        overflow: 'hidden',
        borderRadius: '1rem',
        boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.3s ease-in-out',
        border: '1px solid #f3f4f6',
        position: 'relative',
        height: '100%',
        aspectRatio: '5/3',
        padding: '1%',
    };

    const cardContentStyles = {
        padding: '2%',
    };

    const headerStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        // marginBottom: '1.5rem',
    };

    const titleStyles = {
        fontSize: '1.2rem',
        fontWeight: 600,
        color: '#396598',
        letterSpacing: '0.025em',
    };

    const iconContainerStyles = {
        borderRadius: '50%',
        // backgroundColor: `${getVariantColor()}33`,
        // border: `2px solid ${getVariantColor()}1A`,
        // color: getVariantColor(),
        height: '20px',
        width: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        right: '2%',
    };

    const valueContainerStyles = {
        display: 'flex',
        alignItems: 'baseline',
        marginBottom: '1.5rem',
    };

    const valueStyles = {
        fontSize: '3rem',
        fontWeight: 700,
        color: '#111827',
        letterSpacing: '-0.025em',
    };

    const unitStyles = {
        marginLeft: '0.5rem',
        fontSize: '1.25rem',
        color: '#6B7280',
    };

    const statsContainerStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        // backgroundColor: '#F9FAFB',
        borderRadius: '0.75rem',
        padding: '1rem 0',
    };

    const percentageContainerStyles = {
        position: 'absolute',
        display: 'flex',
        right: '2%',
        alignItems: 'center',
        padding: '6px 10px',
        borderRadius: '12px',
        backgroundColor: isIncreased ? 'rgba(220, 38, 38, 0.1)' : 'rgba(0, 140, 0, 0.1)',
        marginTop: '-35px',
        transition: 'all 0.3s ease',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    };

    const arrowContainerStyles = {
        padding: '6px',
        borderRadius: '8px',
        backgroundColor: isIncreased ?  'rgba(220, 38, 38, 0.2)' : 'rgba(0, 140, 0, 0.2)',
        marginRight: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    const percentageStyles = {
        fontSize: '1rem',
        fontWeight: 600,
        color: isIncreased ?  '#DC2626': '#008000' ,
        letterSpacing: '0.025em',
    };

    const arrowIconStyles = {
        fontSize: '18px',
        color: isIncreased ?  '#DC2626' : '#008000',
        fontWeight: 'bold',
    };

    const dateStyles = {
        fontSize: '1.4rem',
        color: '#555555',
        fontWeight: 500,
    };

    const waveContainerStyles = {
        position: 'absolute',
        bottom: '-5%',
        left: 0,
        right: 0,
        height: '30%',
        overflow: 'hidden',
    };

    const wavePathStyles = {
        fill: getVariantColor(),
        opacity: 0.2,
        animation: 'wave 60s linear infinite',
        transform: 'scale(1.5)',
    };

    const secondWavePathStyles = {
        fill: getVariantColor(),
        opacity: 0.15,
        animation: `wave 80s linear infinite`,
        animationDelay: getWaveDelay(variant),
        transform: 'scale(1.5)',
    };

    const keyframes = `
        @keyframes wave {
            0% {
                transform: translateX(0);
            }
            50% {
                transform: translateX(-50%);
            }
            100% {
                transform: translateX(0);
            }
        }
    `;

    return (
        <>
            <style>{keyframes}</style>
            <div style={cardStyles}>
                <div style={cardContentStyles}>
                    <div style={headerStyles}>
                        <h3 style={titleStyles}>{title}</h3>
                        <Tooltip title="percent change comparing to same month of previous year">
                        <div style={iconContainerStyles}>
                            <InfoCircleOutlined style={{ fontSize: '15px', color: '#ffa500' }} />
                        </div>
                        </Tooltip>
                    </div>
                    <div style={valueContainerStyles}>
                        <span style={valueStyles}>{value}</span>
                        <span style={unitStyles}>{unit}</span>
                    </div>
                    <div style={statsContainerStyles}>
                    <div style={dateStyles}>
                            {startDate}
                            {
                                isIncreased ?  <CaretDownOutlined /> : <CaretUpOutlined />
                            }
                            {endDate}
                        </div>
                        <div style={percentageContainerStyles}>
                            <div style={arrowContainerStyles}>
                                {isIncreased ? (
                                    <ArrowUpOutlined style={arrowIconStyles} />
                                ) : (
                                    <ArrowDownOutlined style={arrowIconStyles} />
                                )}
                            </div>
                            <span style={percentageStyles}>{percent}</span>
                        </div>

                    </div>
                </div>
                <div style={waveContainerStyles}>
                    <svg viewBox="0 0 1200 150" preserveAspectRatio="none" style={{ 
                        width: '200%', 
                        height: '100%', 
                        position: 'absolute',
                    }}>
                        <path
                            d="M0,50 
                               C150,150 350,-50 500,50 
                               C650,150 850,-50 1000,50 
                               C1150,150 1350,-50 1500,50 
                               L1500,150 L0,150 Z"
                            style={wavePathStyles}
                        />
                    </svg>
                    <svg viewBox="0 0 1200 150" preserveAspectRatio="none" style={{ 
                        width: '200%', 
                        height: '100%', 
                        position: 'absolute',
                        transform: 'translateX(-20px)',
                    }}>
                        <path
                            d="M0,30 
                               C150,130 350,-70 500,30 
                               C650,130 850,-70 1000,30 
                               C1150,130 1350,-70 1500,30 
                               L1500,150 L0,150 Z"
                            style={secondWavePathStyles}
                        />
                    </svg>
                </div>
            </div>
        </>
    );
};

export default DashboardCard;
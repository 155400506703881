import React from "react";
import {store} from '@redux/store';
import actions from '@redux/EditableTable/actions';
import EquipmentLookup from "@containers/Lookup/EquipmentLookup";

import _ from "lodash";

import moment from "moment";
import ProjectLookup2 from "@containers/Lookup/ProjectLookup2";
import {Badge, Input, Tooltip} from "antd";
import WorkorderNotification from "@containers/facility/Workorder/WorkorderNotification";

const {TextArea} = Input;

export const WorkorderProps = {
    title: 'Workorder',
    description: '',
    name: 'workorder'
}

const renderDrawer = (text, id) => {
    store.dispatch(actions.OpenDrawer(true, id))
}

export const WorkorderColumns = () => ( [
    {
        title: 'key',
        dataIndex: 'id',
        key: 'id',
        fixed: 'left',
        editable: false,
        search: false,
        importIngore: true,
        hideInTable: false,
        width: 60,
        sorter: (a, b) => a.id - b.id
    },
    // {
    //     title: 'System',
    //     dataIndex: 'system',
    //     key: 'system',
    //     search: false,
    //     edit: true,
    //     hideInTable: window.innerWidth < 760,
    //     sorter: (a, b) => a.system?.localeCompare(b.system),
    //     formItemProps: {
    //         rules: [
    //             {
    //                 required: true,
    //                 message: 'required',
    //             },
    //         ],
    //     },
    //     renderFormItem: () => <TextArea rows={1}/>,
    // },
    {
        title: 'Equipment',
        dataIndex: 'equipment_name',
        key: 'equipment_name',
        edit: true,
        hideInTable: true,
        type: 'select',
        valueType: 'select',
        sorter: (a, b) => a.equipment?.localeCompare(b.equipment),
        render: (text, record) => _.get(record, 'equipment_name'),
        renderFormItem: () => <EquipmentLookup/>
        // renderFormItem: (_, { isEditable }) => {
        //     let value = _.entity.equipment
        //     return <EquipmentLookup value={value}/>
        // },
    },
    {
        title: 'Equipment',
        dataIndex: 'equipment',
        key: 'equipment',
        edit: true,
        width: 100,
        type: 'select',
        valueType: 'select',
        sorter: (a, b) => a.equipment - b.equipment,
        hideInTable: window.innerWidth < 760,
        render: (text, record) => _.get(record, 'equipment_name'),
        renderFormItem: (_, {isEditable}) => {
            let record = _.entity.equipment_name ? _.entity.equipment_name: ''
            return <EquipmentLookup record={record}/>
        },
    },

    {
        title: 'Issue',
        dataIndex: 'issue',
        key: 'issue',
        width: 200,
        fixed: 'left',
        search: false,
        edit: true,
        sorter: (a, b) => a.issue?.localeCompare(b.issue),
        formItemProps: {
            rules: [
                {
                    required: true,
                    message: 'required',
                },
            ],
        },
        render: (text, record, _, action) => (
            <Tooltip title={text}>
            <a onClick={() => action ? renderDrawer(text, record.id) : ''}>{text}</a>
            </Tooltip>
        ),
        renderFormItem: () => <TextArea rows={1}/>,
    },
    {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: 200,
        edit: true,
        required: false,
        search: false,
        hideInTable: false,
        render: text => (
            <Tooltip title={text}>
                <span>{text}</span>
            </Tooltip>
        ),
        renderFormItem: () => <TextArea rows={1}/>,
    },
    {
        title: 'Recommended Action',
        dataIndex: 'recommended_action',
        key: 'recommended_action',
        edit: true,
        width: 100,
        sorter: (a, b) => a?.recommended_action?.localeCompare(b?.recommended_action),
        renderFormItem: () => <TextArea rows={1}/>,
    },
    {
        title: 'Responsible Party',
        dataIndex: 'responsible_party',
        key: 'responsible_party',
        edit: true,
        width: 80,
        sorter: (a, b) => a.responsible_party?.localeCompare(b.responsible_party),
    },
    {
        title: 'Assign To',
        dataIndex: 'assignTo_name',
        key: 'assignTo_name',
        edit: true,
        width: 80,
        sorter: (a, b) => a.assignTo_name?.localeCompare(b.assignTo_name),
    },
    {
        title: 'WorkOrder Number',
        dataIndex: 'workordernumber',
        key: 'workordernumber',
        width: 80,
        search: false,
        edit: true,
        sorter: (a, b) => a.workordernumber?.localeCompare(b.workordernumber),
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        edit: true,
        width: 100,
        render: (text, record) => {
            let statusText = record?.status;
            let style = {
                backgroundColor: '#f0f0f0'
            };
            if (statusText === 'Completed') {
                style = {backgroundColor: '#86efac', color: '#000000'}; // green-300
            } else if (statusText === 'Verifying') {
                style = {backgroundColor: '#93c5fd', color: '#000000'}; // blue-300
            } else if (statusText === 'On-Hold') {
                style = {backgroundColor: '#d1d5db', color: '#4b5563'}; // grey-300, text-grey
            } else if (statusText === 'InProgress') {
                style = {backgroundColor: '#fdba74', color: '#000000'}; // orange-300
            }

            return (
                <div style={{textAlign: 'center', borderRadius: 2,  ...style}}>
                    {statusText}
                </div>
            );
        },
        sorter: (a, b) => a.status?.localeCompare(b.status),
        valueEnum: {
            'Not Started': {text: 'Not Started'},
            InProgress: {text: 'InProgress',},
            'On-hold': {text: 'On-hold',},
            Verifying: {text: 'Verifying',},
            Completed: {text: 'Completed',}
        },
        formItemProps: {
            initialValue: ''
        },
    },
    {
        title: 'Priority',
        dataIndex: 'priority',
        key: 'priority',
        width: 80,
        edit: true,
        sorter: (a, b) => a.priority?.localeCompare(b.priority),
        render: (text, record) => {
            let bgColor = '';
            let statusText = record?.priority;
            if (statusText === 'Urgent') {
                bgColor = 'text-red-500 ';
            }

            return (
                <div className={`${bgColor} align-center py-1 px-2`}>
                    {statusText}
                </div>
            );
        },
        valueEnum: {
            Urgent: {text: 'Urgent'},
            Important: {text: 'Important',},
            Low: {text: 'Low',}
        },
        formItemProps: {
            initialValue: 'Low'
        },
    },
    {
        title: 'Energy Impact',
        dataIndex: 'energy_impact',
        key: 'energy_impact',
        edit: true,
        width: 80,
        sorter: (a, b) => a.energy_impact?.localeCompare(b.energy_impact),
        valueEnum: {
            High: {text: 'High'},
            Medium: {text: 'Medium',},
            Low: {text: 'Low',},
            None: {text: 'None',}
        },
        formItemProps: {
            initialValue: 'Medium'
        },
    },
    {
        title: 'Project',
        dataIndex: 'project_name',
        key: 'project',
        edit: true,
        width: 100,
        type: 'select',
        valueType: 'select',
        hideInTable: false,
        render: (text, record) => _.get(record, 'project_name.name'),
        renderFormItem: (_, {isEditable}) => {
            let record = _.entity.project_name ? _.entity.project_name.name : ''
            return <ProjectLookup2 record={record}/>
        },
    },
    {
        title: 'Complete Date',
        dataIndex: 'complete_date',
        edit: true,
        type: 'date',
        width: 90,
        hideInTable: false,
        className: 'date-editor',
        key: 'complete_date',
        sorter: (a, b) => a.complete_date?.localeCompare(b.complete_date),
        renderFormItem: () => <Input type={'date'}/>,

    },
    {
        title: 'Reported By',
        dataIndex: 'reportBy',
        hideInTable: false,
        width: 90,
        className: 'date-editor',
        key: 'reportBy',
        render: text => (
            <Tooltip title={text}>
                <span>{text}</span>
            </Tooltip>
        ),
        renderFormItem: () => <Input/>,
    },
    {
        title: 'Report Date',
        dataIndex: 'reportDate',
        edit: true,
        type: 'date',
        width: 90,
        className: 'date-editor',
        key: 'reportDate',
        sorter: (a, b) => a.reportDate?.localeCompare(b.reportDate),
        renderFormItem: () => <Input type={'date'}/>,

    },
    {
        title: 'Report Date',
        dataIndex: 'reportDate',
        hideInTable: true,
        className: 'date-editor',
        key: 'reportDate',
    },
    {
        title: 'Modified Date',
        dataIndex: 'modified_date',
        key: 'modified_date',
        width: 90,
        editable: false,
        search: false,
        sorter: (a, b) => a.modified_date - b.modified_date,
        importIngore: true,
        render: (text, record) => (
            moment(record.modified_date).format('YYYY-MM-DD')
        )
    },
    {
        title: 'Created Date',
        dataIndex: 'created_date',
        key: 'created_date',
        width: 90,
        hideInTable: false,
        editable: false,
        importIngore: true,
        sorter: (a, b) => a.created_date - b.created_date,
        render: (text, record) => (
            moment(record.created_date).format('YYYY-MM-DD')
        )
    },
    {
        title: 'CreatedBy',
        dataIndex: 'createdby_name',
        key: 'createdby_name',
        editable: false,
        width: 90,
        search: false,
        importIngore: true,
        hideInTable: false,
    },
    {
        title: 'ModifiedBy',
        dataIndex: 'lastModified_name',
        key: 'lastModified_name',
        editable: false,
        search: false,
        width: 90,
        importIngore: true,
        hideInTable: false,
    },
    {
        title: 'Comments',
        valueType: 'option',
        width: 50,
        fixed: 'right',
        importIngore: true,
        render: (text, record, _, action) => (
            <div className="space-around flex item-center">

                <WorkorderNotification record={record}/>
                {record?.has_attachment  ? <Badge >
                    <i className="fas fa-paperclip text-primary text-2xl "
                       style={{color: 'green', fontSize: '18px'}}></i>
                </Badge> : ''}
            </div>
        ),
    },

    {
        title: 'modified_date',
        dataIndex: 'modified_date',
        key: 'modified_date',
        editable: false,
        search: false,
        importIngore: true,
        hideInTable: true,
    },
]);




import 'mapbox-gl/dist/mapbox-gl.css'
import 'react-map-gl-geocoder/dist/mapbox-gl-geocoder.css'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import MapGL from 'react-map-gl'
import Geocoder from 'react-map-gl-geocoder'
import styled from "styled-components";
import {useDispatch} from "react-redux";
import actions from "@redux/facility/actions";
import _ from 'lodash';
import {Input} from "antd";

const AddressLookupWrapper = styled.div`
  height: 20vh;

  .mapboxgl-ctrl-geocoder {
    width: 100% !important;
    max-width: 100% !important;
  }
`;


const AddressLookup = (props) => {
    // console.log('address')
    const [address, setAddress] = useState(props.value);
    const {formRef} = props;
    const [viewport, setViewport] = useState({
        latitude: 37.7577,
        longitude: -122.4376,
        zoom: 14
    });
    const dispatch = useDispatch();

    const mapRef = useRef();
    const geocoderContainerRef = useRef();

    useEffect(() => {

        if (props.value && props.value.length > 0 && props.value !== null && props.value !== undefined) {
            console.log('props.value1', props.value)
            setAddress(props.value);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.value]);

    const handleViewportChange = useCallback(
        (newViewport) => {
            setViewport(newViewport)
            dispatch(actions.SelectedGeoLocation(newViewport))
        },
        [dispatch]
    );

    const onSelected = useCallback(
        (result) => {
            console.log('address', _.get(result, 'result.place_name'))
            let temp = _.get(result, 'result.place_name');
            dispatch(actions.SelectedAddress(temp))
            setAddress(temp)
            formRef.setFieldsValue({
                [props.fieldName]: temp
            })
        },
        [dispatch, formRef]
    );

    // if you are happy with Geocoder default settings, you can just use handleViewportChange directly
    const handleGeocoderViewportChange = useCallback(
        (newViewport) => {
            // console.log('newViewport', newViewport)
            const geocoderDefaultOverrides = {transitionDuration: 1000, zoom: 14};
            return handleViewportChange({
                ...newViewport,
                ...geocoderDefaultOverrides
            });
        },
        [handleViewportChange]
    );
    return (
        <AddressLookupWrapper>
            <div className={'mb-5'}>
                <Input disabled value={address}/>
            </div>
            <div
                ref={geocoderContainerRef}
                style={{position: "", top: -10, left: 20, zIndex: 1}}
            />
            <MapGL
                ref={mapRef}
                {...viewport}
                width="100%"
                height="70%"
                onViewportChange={handleViewportChange}
                mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                mapStyle="mapbox://styles/mapbox/streets-v11"
            >
                <Geocoder
                    mapRef={mapRef}
                    containerRef={geocoderContainerRef}
                    onViewportChange={handleGeocoderViewportChange}
                    mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                    position="top-left"
                    trackProximity={true}
                    collapsed={true}
                    // inputValue={address}
                    onResult={onSelected}
                    expandFocused={false}

                />
            </MapGL>
        </AddressLookupWrapper>
    );
};

export default AddressLookup

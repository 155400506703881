import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import BubblePlot from "@containers/facility/Benchmark/Chart/BubblePlot";
import BarChart from "@containers/facility/Benchmark/Chart/BarChart";
import { api } from "@redux/api";
import _ from 'lodash';
import { colors } from "@containers/facility/Benchmark/Chart/colors";
import { jsforceResponse } from "@config/httpService";
import { useSelector } from 'react-redux';


const AllFacilityBenchmark = () => {

    const [chart1_data, setChart1Data] = useState([]);
    const [chart2_data, setChart2Data] = useState([]);
    const [chart3_data, setChart3Data] = useState([]);
    const [colors_type, setColorsType] = useState([]);

    const CurrentCompany = useSelector(state => state.Facility?.selected?.record?.company_id);

    useEffect(() => {
        const fetchData = async () => {
            let result = await api.getFacilityBenchmark('');

            result = jsforceResponse(result);
            result = _.get(result, 'data.results')
            let all_facilities = result;
            let chart1_data = [], chart2_data = [], chart3_data = [], colors_type = [];
            let isCurrentId = 0;

            for (let i = 0; i < all_facilities.length; i++) {

                if (all_facilities[i]['company_id'] === CurrentCompany) {
                    isCurrentId = all_facilities[i]['id'];
                    all_facilities[i]['isCurrent'] = true
                }

                colors_type[all_facilities[i]['category']] = colors[i]

                const year = all_facilities[i]['year'] ? + all_facilities[i]['year'] + '' : '';
                const eui = all_facilities[i]['eui'] ? + all_facilities[i]['eui'] : '';
                const energy_costs_sqft = all_facilities[i]['energy_costs_sqft'] ? + all_facilities[i]['energy_costs_sqft'] : '';
                const size = all_facilities[i]['size'] ? + all_facilities[i]['size'] : '';
                const category = all_facilities[i]['category'] ? all_facilities[i]['category'] : '';
                const isCurrent = all_facilities[i]['company_id'] === CurrentCompany;

                if (year && eui && size) {
                    chart1_data.push({
                        key: i,
                        xAxis: year,
                        yAxis: eui,
                        size,
                        type: category,
                        isCurrent,
                    })
                }

                if (year && energy_costs_sqft && size) {
                    chart2_data.push({
                        key: i,
                        xAxis: year,
                        yAxis: energy_costs_sqft,
                        size,
                        type: category,
                        isCurrent,
                    })
                }
                // chart2_data.push({
                //     key: i,
                //     xAxis: all_facilities[i]['year'],
                //     yAxis: all_facilities[i]['energy_costs_sqft'],
                //     size: parseInt(all_facilities[i]['size']),
                //     type: all_facilities[i]['category'],
                //     isCurrent: all_facilities[i]['company_id'] === CurrentCompany
                // })

                chart3_data.push({
                    key: i.toString(),
                    xField: all_facilities[i]['facility'],
                    yField: all_facilities[i]['eui'] ? all_facilities[i]['eui'] : 0,
                    type: all_facilities[i]['category'],
                    isCurrent: all_facilities[i]['company_id'] === CurrentCompany
                })

            }

            chart1_data = chart1_data.sort((a, b) => a.xAxis - b.xAxis)
            chart2_data = chart2_data.sort((a, b) => a.xAxis - b.xAxis)
            chart3_data = chart3_data.sort((a, b) => b.yField - a.yField)

            // isCurrentIndex = chart3_data.findIndex(e => e.id === isCurrentId)

            // chart3_data = chart3_data
            setChart1Data(chart1_data);
            setChart2Data(chart2_data);
            setChart3Data(chart3_data);
            setColorsType(colors_type);

        }
        fetchData();
    }, [])

    return (
        <Row className={'w-full p-4'} gutter={[16, 16]}>
            <Col xs={24} md={12} xl={12}>

                <BubblePlot fillOpacity={0.5}
                    opacity={0.65}
                    data={chart1_data} title='EUI (kBtu/sq-yr)  by Year'
                    xLabel='Year' yLabel='EUI'
                    colors={colors_type} />

            </Col>
            <Col xs={24} md={12} xl={12}>

                <BubblePlot fillOpacity={0.5}
                    opacity={0.65}
                    xLabel='Year' yLabel='Costs ($/SQFT)  by Year'
                    data={chart2_data} title='Energy Costs ($/SQFT)'
                    colors={colors_type} />


            </Col>
            <Col xs={24} md={24} xl={24}>

                <BarChart data={chart3_data}
                    title='EUI (kBtu/sq-yr)'
                    fillOpacity={1}
                    opacity={1}
                    xLabel='Facility' yLabel='EUI'
                    height={360} />
            </Col>

        </Row>
    );
}

export default AllFacilityBenchmark;

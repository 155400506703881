import React, { useEffect, useRef } from "react";
import { Button, Popconfirm, message } from "antd";
import { usePdfContext } from "../context";
import { MenuActionsWrap } from "../components/MenuActions";
import { PDFSummaryChart } from "../template/utility/pdf-summary-chart";

import { SavingAnalysisChart } from "../template/utility/savingAnalysis-chart";
import { DashboardChart, chartIdDashBordMappings } from "../template/dashboard/dashboard-chart";

import styled from "styled-components";
import backgroundPdfPNG from '@assets/images/pdf-background.png'
import layerPdfPNG from '@assets/images/pdf-layer.png'
import './index.css'
import { toPng } from 'html-to-image'

function getBase64FromHiddenImage(imgElement) {
  return new Promise((resolve, reject) => {
    if (imgElement.complete) {
      processImage();
    } else {
      imgElement.onload = processImage;
      imgElement.onerror = reject;
    }

    function processImage() {
      const canvas = document.createElement('canvas');
      canvas.width = imgElement.width;
      canvas.height = imgElement.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(imgElement, 0, 0);
      resolve(canvas.toDataURL('image/png'));
    }
  });
}

// const UtilityWrap = styled.div`
//  * {
//   box-shadow: unset !important;
//   border-width: 0 !important;
//   border-radius: 0 !important;
//   font-family: unset !important;
//   text-shadow: unset !important;
//   outline:none !important;
// }
// `

const UtilityWrap = styled.div``

const getObjectsByElementIds = (elementIds = [], ...keys) => {
  return Promise.all(elementIds.map(async (id) => {
    let container;
    let canvas;

    if (id.startsWith('Dashboard_cards_')) {
      container = document.querySelector(`.${id}`);
      const img = await toPng(container)

      const i = document.createElement('img')
      i.src = img
      document.body.appendChild(i)

      return {
        id: id,
        img: img
      }
    } else if (id === 'ChartsClassName-KeyStatistics') {
      container = document.querySelector(`.${id}`);
      canvas = container.querySelector('canvas')
      const tableProps = container.__tableData__ || {};
      return {
        id: id,
        props: {
          dataSource: tableProps.dataSource,
          columns: tableProps.columns,
          title: tableProps.title,
          start_month: tableProps.start_month,
          end_month: tableProps.end_month,
        }
      }
    } else {
      container = document.querySelector(`.${id}`);
      if(!container) {
        console.info(id)
      }
      canvas = container.querySelector('canvas')
    }

    const objects = keys.reduce((pre, key) => {
      const element = container.querySelector('.' + key);
      return {
        ...pre,
        [key]: element?.textContent
      }
    }, {})

    if (!canvas) {
      return
    }

    return {
      id: id,
      img: canvas?.toDataURL('image/png', 1.0),
      ...objects
    }
  }))
}


const PdfLoading = () => {
  const { pdfFormValue, goToViewStep, setPdfImages, goToFormStep } = usePdfContext();
  const { endingMonth, periodItem, period, savingAnalysis, savingAnalysisItem, utilityVisible, dashboardVisible } = pdfFormValue;
  const hasSummaryCharts = Array.isArray(periodItem) && periodItem.length > 0;
  const hasSavingAnalysisItemCharts = Array.isArray(savingAnalysisItem) && savingAnalysisItem.length > 0;

  const backgroundImageRef = useRef();
  const layerImageRef = useRef();

  const timeRef = useRef(false);

  useEffect(() => {
    setTimeout(() => {
      timeRef.current = true
    }, 2000)
  }, [])



  const renderChat = async () => {
    let summary = [],
      period = 12,
      savingAnalysis = [];

    if (utilityVisible) {
      if (hasSummaryCharts) summary = await getObjectsByElementIds(periodItem);
      if (hasSavingAnalysisItemCharts) savingAnalysis = await getObjectsByElementIds(savingAnalysisItem);
    }

    let dashboardCard = [];
    let dashboardCharts = [];

    if (dashboardVisible) {
      dashboardCard = await getObjectsByElementIds(chartIdDashBordMappings.cards);
      dashboardCharts = await getObjectsByElementIds(chartIdDashBordMappings.charts);
    }

    return Promise.all(
      [
        getBase64FromHiddenImage(backgroundImageRef.current),
        getBase64FromHiddenImage(layerImageRef.current),
      ]
    ).then(([backgroundImage, layerImage]) => {

      const otherFiles = renderOtherFields(
        "pdfChart_Elec_Baseline",
        "pdfChart_Elec_Post",
        "pdfChart_Elec_Price",
        "pdfChart_Elec_Segment",
        "pdfChart_Gas_Baseline",
        "pdfChart_Gas_Post",
        "pdfChart_Gas_Price",
        "pdfChart_Gas_Segment",
        "chartId_SummaryTitle",
        "chartId_PeriodTitle"
      )

      setPdfImages({
        summary,
        period,
        savingAnalysis,
        dashboardCard,
        dashboardCharts,
        backgroundImage,
        layerImage,
        ...otherFiles
      })
    }).catch(() => {
      message.error('load imgages error')
    })
  }

  const renderOtherFields = (...fields) => {
    return fields.reduce((pre, field) => {
      const element = document.querySelector('.' + field);
      if (element) {
        return {
          ...pre,
          [field]: element.textContent
        }
      } else {
        return pre
      }
    }, {})
  }

  const handleGenerateReport = async () => {
    if (!timeRef.current) return message.warn('Please wait a moment before trying again.')
    await renderChat()
    goToViewStep()
  }

  const renderUtility = () => {
    return utilityVisible ? <UtilityWrap>
      {hasSummaryCharts && period && <PDFSummaryChart period={period} endingMonth={endingMonth} />}
      {/* {hasPeriodItemCharts && <EnergyConsumptionChart period={period} />} */}
      {hasSavingAnalysisItemCharts && <SavingAnalysisChart savingAnalysis={savingAnalysis} />}
    </UtilityWrap> : null
  }

  return React.useMemo(() => (
    <div className="flex items-center justify-center">
      <MenuActionsWrap>
        <Button htmlType='submit' onClick={goToFormStep}>Back</Button>
        <Popconfirm
          placement="bottomLeft"
          overlayClassName="pdfGenerateReportconfirm"
          title={
            <>
              <div>Have you verified that all charts are accurate? </div>
              <div>If everything is correct, please click OK.</div>
            </>
          }
          onConfirm={handleGenerateReport}
          okText="OK"
          cancelText="Cancel"
        >
          <Button style={{ marginLeft: 10 }} type='primary' htmlType='submit'>Generate Report</Button>
        </Popconfirm>

      </MenuActionsWrap>

      <div className="chart-wrapper" style={{ width: 1280 }}>
        {dashboardVisible && <DashboardChart />}
        {renderUtility()}
        <img ref={backgroundImageRef} style={{ visibility: 'hidden' }} src={backgroundPdfPNG} alt=''></img>
        <img ref={layerImageRef} style={{ visibility: 'hidden' }} src={layerPdfPNG} alt=''></img>
      </div>
    </div>
  ), []);
}

export default PdfLoading;

import React from "react";
import moment from "moment";
import { store } from "@redux/store";
import actions from "@redux/EditableTable/actions";
import { NoDecimalFormat, SingleDecimalFormat, TwoDecimalFormat } from "@components/Format";
import CertifyStatusTag from "../CertifyStatus";
import { useNavigate } from "react-router-dom";

export const GasProps = {
    name: 'gas',
    title: 'Gas',
    description: 'Saved user operation filters'
}

const renderDrawer = (text, id) => {
    store.dispatch(actions.OpenDrawer(true, id))
}


const GotoGasDetailDetail = ({ id }) => {

    const navigate = useNavigate()

    return <a onClick={(e) => {
        e.stopPropagation();
        navigate(`/GasDetail/${id}`)
    }}>
        <i className="fas fa-expand-arrows-alt text-lg"></i>
    </a>
}

export const GasColumns = [
    {
        title: 'key',
        dataIndex: 'id',
        key: 'id',
        editable: false,
        search: false,
        importIngore: true,
        hideInTable: true,
        width: '4%'
    },

    {
        title: 'StartDate',
        dataIndex: 'service_start_date',
        key: 'service_start_date',
        valueType: 'date',
        width: '100px',
        search: false,
        editable: true,
        formItemProps: {
            rules: [
                {
                    required: true,
                    message: 'required',
                },
            ],
        },
        sorter: (a, b) => new Date(a.service_start_date) - new Date(b.service_start_date)
    },
    {
        title: 'EndDate',
        dataIndex: 'end_start_date',
        key: 'end_start_date',
        valueType: 'date',
        width: '100px',
        search: false,
        editable: true,
        formItemProps: {
            rules: [
                {
                    required: true,
                    message: 'required',
                },
            ],
        },
        sorter: (a, b) => new Date(a.end_start_date) - new Date(b.end_start_date)
    },
    {
        title: 'status',
        dataIndex: 'certStatus',
        key: 'certStatus',
        render: (text) => !text || text === '-' ? '-' : <CertifyStatusTag status={text} />
    },
    {
        title: 'ServiceMonth',
        dataIndex: 'service_month',
        key: 'service_month',
        valueType: 'date',
        width: '100px',
        search: false,
        editable: true,
        formItemProps: {
            rules: [
                {
                    required: true,
                    message: 'required',
                },
            ],
        },
        sorter: (a, b) => new Date(a.service_month) - new Date(b.service_month)
    },
    {
        title: <div className={'text-right'}>Days</div>,
        dataIndex: 'service_date',
        key: 'service_date',
        width: '50px',
        editable: true,
        sorter: (a, b) => a.service_date - b.service_date,
        render: text => <div
            className={text < 15 ? 'text-right text-red-500' : 'text-right'}>{NoDecimalFormat(text)}</div>,
        renderFormItem: () => ''
    },
    {
        title: <div className={'text-right'}>Usage(MMBtu)</div>,
        dataIndex: 'usage',
        key: 'usage',
        width: '100px',
        required: true,
        edit: true,
        formItemProps: {
            rules: [
                {
                    required: true,
                    message: 'required',
                },
            ],
        },
        sorter: (a, b) => a.usage - b.usage,
        render: text => <div
            className={text < 15 ? 'text-right text-red-500' : 'text-right'}>{TwoDecimalFormat(text)}</div>
    },
    {
        title: <div className={'text-right'}>Avg. OAT(°F)</div>,
        dataIndex: 'avgoat',
        key: 'avgoat',
        width: '100px',
        required: false,
        edit: true,
        // formItemProps: {
        //     rules: [
        //         {
        //             required: true,
        //             message: 'required',
        //         },
        //     ],
        // },
        sorter: (a, b) => a.avgoat - b.avgoat,
        render: text => <div
            className={text < 15 ? 'text-right text-red-500' : 'text-right'}>{SingleDecimalFormat(text)}</div>
    },
    // {
    //     title: 'Area(Sqt)',
    //     dataIndex: 'area',
    //     key: 'area',
    //     edit: true,
    //     formItemProps: {
    //         rules: [
    //             {
    //                 required: true,
    //                 message: 'required',
    //             },
    //         ],
    //     },
    //     hideInTable: window.innerWidth < 760,
    //     sorter: (a, b) => a.area - b.area,
    //     render: text => NoDecimalFormat(text)
    // },
    {
        title: <div className={'text-right'}>Costs($)</div>,
        dataIndex: 'costs',
        key: 'costs',
        edit: true,
        width: '100px',
        required: true,
        formItemProps: {
            rules: [
                {
                    required: true,
                    message: 'required',
                },
            ],
        },
        hideInTable: window.innerWidth < 760,
        sorter: (a, b) => a.costs - b.costs,
        render: text => <div
            className={text < 15 ? 'text-right text-red-500' : 'text-right'}>{TwoDecimalFormat(text)}</div>
    },
    {
        title: <div className={'text-right'}>Adjustment(MMBtu)</div>,
        dataIndex: 'adjustment',
        key: 'adjustment',
        edit: true,
        width: '100px',
        hideInTable: window.innerWidth < 760,
        sorter: (a, b) => a.adjustment - b.adjustment,
        render: text => text => <div
            className={text < 15 ? 'text-right text-red-500' : 'text-right'}>{NoDecimalFormat(text)}</div>
    },
    {
        title: 'Modified Date',
        dataIndex: 'modified_date',
        key: 'modified_date',
        width: '120px',
        editable: true,
        importIngore: true,
        hideInTable: window.innerWidth < 760,
        sorter: (a, b) => a.modified_date - b.modified_date,
        render: (text) => (
            moment(text).format('YYYY-MM-DD')
        ),
        renderFormItem: () => ''
    },
    {
        title: '',
        valueType: 'option',
        width: '8rem',
        importIngore: true,
        render: (text, record, _, action) => (
            <div className={'space-around'}>
                <a
                    key="editable"
                    onClick={() => (action?.startEditable?.(record.id))}
                >
                    <i className="fas fa-edit text-lg"></i>
                </a>
                <GotoGasDetailDetail id={record.id} />
            </div>
        ),
    },
];
